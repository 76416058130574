import React from 'react'
import { Header } from '../../SitePages/Header'
import { ArticleList } from '../ArticleList'

export const HomeCustomize = () => {
  return (
    <div>
      <Header />
      <ArticleList />
    </div>
  )
}
