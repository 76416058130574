import React from 'react'
import { Header } from '../../SitePages/Header'
import Editor from '../Editor';

export const NewArticle = () => {
  return (
    <div>
        <Header/>
        <Editor/>
    </div>
  )
}
