import React from 'react';
import './App.css';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { AppComponent } from './Component/App';
import { Helmet } from 'react-helmet';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1368bf',
    },
  },
});

function App() {
  return (
    <div className="App">
      <Helmet>
        <title>Bitveen.com | START WRITING BLOGS</title>
        <meta name="title" content="Bitveen.com | START WRITING BLOGS" />
        <meta name="description" content="If you have content to write, knowledge to share, an information to spread, or to tell about your business — use Bitveen.com - Signin for free and start writing." />
        {/* <meta name="theme-color" content="#008f68" /> */}
      </Helmet>
      <ThemeProvider theme={theme}> <AppComponent /> </ThemeProvider>
    </div>
  );
}

export default App;
